<template>
  <div>
    <v-container class="px-5">
      <v-card class="mx-auto" max-width="900" outlined color="transparent">
        <v-card-text>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn color="#9575CD" dark @click="isEnglish = !isEnglish">{{
                isEnglish == false ? 'English' : 'Korean'
              }}</v-btn>
            </v-col>
          </v-row>
          <div class="mt-10 text--primary text-h5">
            <span>Affirming Church</span>
          </div>
          <v-divider></v-divider>
          <br />
          <div class="text--primary" v-show="isEnglish == false">
            <h3 class="font-weight-bold mb-4" style="color:#9575CD;">
              단체 소개
            </h3>
            <p>
              <b>Affirm united</b>는 모든 성 정체성과 성적 지향을 캐나다연합교회
              및 캐나다 사회 안에 완전히 담아내기 위해 일한다.
            </p>
            <p>
              우리의 힘과 희망의 원천은
            </p>
            <ul>
              <li>성서 안에 있는 해방의 서사다.</li>
              <li>
                정의롭게 살고 온유하게 사랑하며 하나님과 함께 겸손히 걸으라는
                예언자적 부르심이다.
              </li>
              <li>하나님의 평등한 사랑을 증거하신 예수이다.</li>
            </ul>
            <br />
            <p>
              우리는 사람들의 모든 섹슈얼리티, 성 정체성, 성적 표현에 대한
              목소리이자 지지자이다. 우리는 어퍼밍(Affirming) 사역 프로그램을
              통해 사역의 장소들이 오롯이 환대하는 곳, 안전한 곳, 거룩한 곳이
              되도록 돕는다.
            </p>
            <p>
              회원가입 및 참여는 모든 성 정체성과 섹슈얼리티를 가진 사람들에게
              열려있다.
            </p>
            <v-divider></v-divider>
            <h3 class="mt-5 font-weight-bold mb-4" style="color:#9575CD;">
              Affirming 교회 찾기
            </h3>
            <ul>
              <li>
                Communities of Faith (formerly called “congregations”):
                <ul>
                  <li>
                    <a
                      title="British Columbia"
                      href="http://affirmunited.ause.ca/british-columbia/"
                      >British Columbia</a
                    >
                  </li>
                  <li>
                    <a href="http://affirmunited.ause.ca/northwest-territory/"
                      >Northwest Territories</a
                    >
                  </li>
                  <li>
                    <a
                      title="Alberta"
                      href="http://affirmunited.ause.ca/alberta/"
                      >Alberta</a
                    >
                  </li>
                  <li>
                    <a
                      title="Saskatchewan"
                      href="http://affirmunited.ause.ca/saskatchewan/"
                      >Saskatchewan</a
                    >
                  </li>
                  <li>
                    <a
                      title="Manitoba"
                      href="http://affirmunited.ause.ca/manitoba/"
                      >Manitoba</a
                    >
                  </li>
                  <li>
                    <a
                      title="Ontario"
                      href="http://affirmunited.ause.ca/ontario/"
                      >Ontario</a
                    >
                  </li>
                  <li>
                    <a title="Quebec" href="http://affirmunited.ause.ca/quebec/"
                      >Quebec</a
                    >
                  </li>
                  <li>
                    <a href="http://affirmunited.ause.ca/new-brunswick/"
                      >New Brunswick</a
                    >
                  </li>
                  <li>
                    <a
                      title="Nova Scotia"
                      href="http://affirmunited.ause.ca/nova-scotia/"
                      >Nova Scotia</a
                    >
                  </li>
                  <li>
                    <a href="http://affirmunited.ause.ca/prince-edward-island/"
                      >Prince Edward Island</a
                    >
                  </li>
                  <li>
                    <a
                      title="Newfoundland &amp; Labrador"
                      href="http://affirmunited.ause.ca/newfoundland-labrador/"
                      >Newfoundland &amp; Labrador</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="http://affirmunited.ause.ca/other-church-courts-and-organizations/"
                  >Other Church Courts and Organizations</a
                >
                <ul>
                  <li>
                    Regional Councils (formerly Presbyteries and Conferences)
                  </li>
                  <li>Assisted Living Homes</li>
                  <li>Educational Institutions</li>
                  <li>Education and Retreat Centres</li>
                  <li>Groups</li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="text--primary" v-show="isEnglish == true">
            <h3 class="font-weight-bold mb-4" style="color:#9575CD;">
              Who are we?
            </h3>
            <p>
              <b>Affirm united</b> works for the full inclusion of people of all
              gender identities and sexual orientations in the United Church of
              Canada and in society.
            </p>
            <p>
              We draw strength and hope from biblical stories of liberation;
              from the prophetic call to live justly, love tenderly, and walk
              humbly with God; and from Jesus’ witness to God’s inclusive love.
            </p>
            <p>
              We’re a voice and a support for people of all sexualities and
              gender identities and expressions, and we help ministries become
              truly welcoming safer sacred spaces through our Affirming
              ministries program.
            </p>
            <p>
              Membership and participation are open to people of all gender
              identities and sexualities.
            </p>
            <v-divider></v-divider>
            <h3 class="mt-5 font-weight-bold mb-4" style="color:#9575CD;">
              Find a ministry
            </h3>
            <ul>
              <li>
                Communities of Faith (formerly called “congregations”):
                <ul>
                  <li>
                    <a
                      title="British Columbia"
                      href="http://affirmunited.ause.ca/british-columbia/"
                      >British Columbia</a
                    >
                  </li>
                  <li>
                    <a href="http://affirmunited.ause.ca/northwest-territory/"
                      >Northwest Territories</a
                    >
                  </li>
                  <li>
                    <a
                      title="Alberta"
                      href="http://affirmunited.ause.ca/alberta/"
                      >Alberta</a
                    >
                  </li>
                  <li>
                    <a
                      title="Saskatchewan"
                      href="http://affirmunited.ause.ca/saskatchewan/"
                      >Saskatchewan</a
                    >
                  </li>
                  <li>
                    <a
                      title="Manitoba"
                      href="http://affirmunited.ause.ca/manitoba/"
                      >Manitoba</a
                    >
                  </li>
                  <li>
                    <a
                      title="Ontario"
                      href="http://affirmunited.ause.ca/ontario/"
                      >Ontario</a
                    >
                  </li>
                  <li>
                    <a title="Quebec" href="http://affirmunited.ause.ca/quebec/"
                      >Quebec</a
                    >
                  </li>
                  <li>
                    <a href="http://affirmunited.ause.ca/new-brunswick/"
                      >New Brunswick</a
                    >
                  </li>
                  <li>
                    <a
                      title="Nova Scotia"
                      href="http://affirmunited.ause.ca/nova-scotia/"
                      >Nova Scotia</a
                    >
                  </li>
                  <li>
                    <a href="http://affirmunited.ause.ca/prince-edward-island/"
                      >Prince Edward Island</a
                    >
                  </li>
                  <li>
                    <a
                      title="Newfoundland &amp; Labrador"
                      href="http://affirmunited.ause.ca/newfoundland-labrador/"
                      >Newfoundland &amp; Labrador</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="http://affirmunited.ause.ca/other-church-courts-and-organizations/"
                  >Other Church Courts and Organizations</a
                >
                <ul>
                  <li>
                    Regional Councils (formerly Presbyteries and Conferences)
                  </li>
                  <li>Assisted Living Homes</li>
                  <li>Educational Institutions</li>
                  <li>Education and Retreat Centres</li>
                  <li>Groups</li>
                </ul>
              </li>
            </ul>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Introduce',
  data() {
    return {
      isEnglish: false,
    };
  },
};
</script>

<style lang="scss" scoped>
span {
  font-weight: bold;
  color: #9575cd;
}
.circle-image {
  border-radius: 100%;
}
ul {
  list-style: none;
}
ul li::before {
  content: '\2022';
  color: #4caf50;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

a {
  color: #000;
}
* {
  line-height: 2;
}
</style>
